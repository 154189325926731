import { Component, Input, OnInit } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrowLeft,
  faChartBar,
  faTachometerAlt,
  IconDefinition
 } from '@fortawesome/free-solid-svg-icons';

import { NavigationLoaction } from 'src/app/services/authorization-service/authorization.service';
import { SideMenuService } from 'src/app/services/side-menu-service/side-menu.service';
import { IconType } from 'src/app/shared/icon/configuration/icon-type';
import { StoreService } from 'src/app/shared/store.service';

import { ProductType } from '../../models/enums/product-type.enum';
import { ReportDescriptionService } from '../../reports/services/report-description.service';
import { ContextSideMenuConfiguration } from '../configurations/context-side-menu-configuration';
import { ContextSideNavigationType } from '../configurations/context-side-navigation-type';
import { accordionIconConfiguration, AccordionType } from '../configurations/side-menu-buttons.configuration';
import { ContextSideNavigationService } from './../services/context-side-navigation.service';

@Component({
  selector: 'porcupine-context-side-navigation',
  templateUrl: './context-side-navigation.component.html',
  styleUrls: ['./context-side-navigation.component.scss']
})
export class ContextSideNavigationComponent implements OnInit {
  @Input() public activeStreamId: string;

  public isFolded$: Observable<boolean> = this.sideMenuService.isMenuFolded();

  public configuration$: Observable<ContextSideMenuConfiguration>;
  public iconType: typeof IconType =  IconType;
  public contextMenuType: typeof ContextSideNavigationType =  ContextSideNavigationType;

  public icons: { [key:string]: IconDefinition } = {
    faArrowLeft,
    faChartBar,
    faTachometerAlt,
    faAngleDoubleLeft,
    faAngleDoubleRight,
  }

  constructor(
    private sideMenuService: SideMenuService,
    private storeService: StoreService,
    private contextSideNavigationService: ContextSideNavigationService,
    private reportDescriptionService: ReportDescriptionService,
    ) {}

  public ngOnInit(): void {
    this.configuration$ = combineLatest([
      this.storeService.getProducts(),
      this.contextSideNavigationService.getConfiguration(),
      this.storeService.getActiveUserId(),
      this.reportDescriptionService.getProducts(),
      this.reportDescriptionService.getTranslatedConfig(),
      this.storeService.getLicenseProducts(),
    ]).pipe(map(([navigation, config, userId, translation, configuration, licence]) => {

      return {
        ...config,
        navigation: navigation,
        sideMenuGuiConfiguration: this.prepareSideNavigation(navigation, translation, configuration, licence),
        dashboardButton: configuration.get(ProductType.DASHBOARD),
        userId,
        ids: {
          streamId: this.activeStreamId,
          userId: userId,
        }
      }
    }))
  }

  public back(backNavigation: NavigationLoaction): void {
    this.storeService.clearActiveStreamAndGoDashboard(backNavigation);
  }

  public open(): void {
    this.sideMenuService.expandMenu();
  }

  public close(): void {
    this.sideMenuService.collapseMenu();
  }

  private prepareSideNavigation(
    navigation: ProductType[],
    translation: {[key:string]: string | any},
    configuration: any,
    license: any
  ): any[] {
    const {
      hasLoanDefault,
      hasCollection,
      hasCandidateSuccess,
      hasEmployeeChurn,
      hasSalesConversion,
      hasPersonalityAttribute,
      hasWellness,
      hasMentalHealth,
    } = this.getAvailableProducts(navigation);

    const config = [];

    if (hasLoanDefault)
      this.addConfiguration(config, [translation.riskManagment], AccordionType.FOR_RISK_MANAGMENT, [ProductType.LOAN_DEFAULT], configuration);

    if (hasCollection)
      this.addConfiguration(config, [translation.collection], AccordionType.COLLECTION, [ProductType.COLLECTIONS], configuration);

    if (hasCandidateSuccess || hasEmployeeChurn) {
      const products = []
      hasCandidateSuccess && products.push(ProductType.CANDIDATE_SUCCESS);
      hasEmployeeChurn && products.push(ProductType.EMPLOYEE_CHURN);
      this.addConfiguration(config, [translation.talent, translation.recruting], AccordionType.FOR_TALENT, products, configuration);
    }

    if (hasSalesConversion || hasPersonalityAttribute) {
      const products = []
      hasSalesConversion && products.push(ProductType.SALES_CONVERSION);
      hasPersonalityAttribute && products.push(ProductType.PERSONALITY_ATTRIBUTE);
      this.addConfiguration(config, [translation.sales, translation.conversion], AccordionType.FOR_SALES, products, configuration);

    }
    if (hasWellness || hasMentalHealth) {
      const products = []
      hasWellness && products.push(ProductType.WELLNESS);
      hasMentalHealth && products.push(ProductType.MENTAL_HEALTH);
      this.addConfiguration(config, [translation.mentalHealth, translation.wellness], AccordionType.MENTAL_HEALTH, products, configuration);
    }

    const customProducts = navigation.filter((v) => {
      return !([
        ProductType.DASHBOARD,
        ProductType.LOAN_DEFAULT,
        ProductType.COLLECTIONS,
        ProductType.CANDIDATE_SUCCESS,
        ProductType.EMPLOYEE_CHURN,
        ProductType.SALES_CONVERSION,
        ProductType.PERSONALITY_ATTRIBUTE,
        ProductType.WELLNESS,
        ProductType.MENTAL_HEALTH,
      ].some(p => p === v))
    });

    if (customProducts.length > 0) {
      this.addConfiguration(
        config,
        ['Custom products'],
        AccordionType.CUSTOM,
        customProducts,
        customProducts.reduce((a, c) => {
          const product = license.licenseProducts.find(p => p.productName === c)
          const label = product.displayName || c;

          let shortName;
          const arr = label.split('-').join(' ').split(' ');
          if (arr.length > 1) shortName = `${arr[0][0].toUpperCase()}${arr[1][0].toUpperCase()}`;
          if (arr.length === 1) shortName = `${arr[0][0].toUpperCase()}${arr[0][1].toUpperCase()}`;
          a.set(c, {
            icon: undefined,
            label: translation.customProducts?.[product.productName]?.title || product.displayName || product.productName,
            faIcon: undefined,
            shortName,
            urlSuffix: `/custom/${c}`
          })

          return a;
        }, new Map())
      );
    }

    return config;
  }

  private addConfiguration(configArr: any[], title: string[], accordionType: AccordionType, productType: ProductType[], configuration): any {
    configArr.push({
      id: accordionType,
      ...accordionIconConfiguration.get(accordionType),
      title,
      buttons: productType.map(p => configuration.get(p))
    })
  }

  private getAvailableProducts(navigation: ProductType[]): {
    hasLoanDefault: boolean;
    hasCollection: boolean;
    hasCandidateSuccess: boolean;
    hasEmployeeChurn: boolean;
    hasSalesConversion: boolean;
    hasPersonalityAttribute: boolean;
    hasWellness: boolean;
    hasMentalHealth: boolean;
  } {
    return {
      hasLoanDefault: navigation?.includes(ProductType.LOAN_DEFAULT),
      hasCollection: navigation?.includes(ProductType.COLLECTIONS),
      hasCandidateSuccess: navigation?.includes(ProductType.CANDIDATE_SUCCESS),
      hasEmployeeChurn: navigation?.includes(ProductType.EMPLOYEE_CHURN),
      hasSalesConversion: navigation?.includes(ProductType.SALES_CONVERSION),
      hasPersonalityAttribute: navigation?.includes(ProductType.PERSONALITY_ATTRIBUTE),
      hasWellness: navigation?.includes(ProductType.WELLNESS),
      hasMentalHealth: navigation?.includes(ProductType.MENTAL_HEALTH),
    }
  }
}
